.s3 {
  &-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    &-label {
      font-size: 12px;
      margin-bottom: 0.2rem;
    }
  }
}

.overflow-unset {
  overflow: unset;
}

.w-50 {
  width: 50%;
}

.s3-modal-content {
  margin-bottom: 7rem;
}
