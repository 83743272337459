@import "../../../scss/variables.scss";

.dropdown {
  box-sizing: border-box;

  &-sort {
    display: block;
    height: 40px;
    margin-right: 0px;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    min-width: 160px;
  }

  &-toggle .tooltip {
    display: none;
    //top: 0.7rem;
    right: 10px;
    position: absolute;
    width: 100%;
    /* height: 40%; */
    /* background-color: #f3f3f3; */
    color: #f3f3f3;
    text-align: center;
    padding: 5px 5px;
    /* border-radius: 6px; */
    /* z-index: 1; */
    /* opacity: 0; */
    /* transition: opacity 0.6s; */
    font-size: small;
  }

  &-search {
    height: 30px;
    position: fixed;
    padding: 5px;
    z-index: 1000;
    background: #252525;
    input[type="text"] {
      height: 20px;
      width: 100%;
      border: none;
      color: #f3f3f3;
      background: #252525;
    }
  }

  &-ignore {
    outline: none;
  }

  &-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //width: 100%;
    height: 100%;
    padding: 0px 5px 0px 1rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    // border-bottom: 1px solid #8c8c8c;
    //background-color: #282828;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    color: #f3f3f3;
    font-size: 0.875rem;
    font-weight: 400;

    .w--open {
      border-bottom-color: #3d3d3d;
    }
  }

  &-toggle__icon {
    position: absolute;
    right: 0rem;
    width: 1rem;
    height: 1rem;
    background-image: url("./arrow__down.svg");
    background-position: 50% 50%;
    background-size: 10px;
    background-repeat: no-repeat;
    -webkit-filter: invert(100%);
    filter: invert(100%);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    right: 14px;
  }

  &-default {
    border-color: #8c8c8c;
    border-style: solid;
    border-width: 0 0 1px 0;
    background: #282828;
    min-width: 220px;

    &:hover {
      background: #353535;
    }
  }

  &-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 15px;
    height: 15px;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent !important;
    background-size: 15px 15px !important;
    background-repeat: no-repeat !important;
    position: absolute;
    right: 16px;
    top: calc(50% - 7.5px);
  }

  &-link {
    overflow-x: hidden;
    overflow-y: auto;
    height: 2.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 0.6875rem 0px;
    border-top: 1px solid #565656;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    color: #bebebe;
    line-height: 1rem;

    &:hover {
      color: #f3f3f3;
    }
    .dropdown-link__first {
      border-top-style: none;
    }
  }

  &-list {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #252525;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;

    &.w--open {
      background-color: #252525;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      //width: 100%;
      height: 100%;
      padding: 0px 5px 0px 1rem;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: 1px solid #8c8c8c;
      background-color: #282828;
      -webkit-transition: all 70ms ease;
      transition: all 70ms ease;
      color: #f3f3f3;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  &-item {
    font-size: $fontSizeLarge;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    min-height: 2.5rem;
    padding: 5px 16px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    color: #bebebe;

    &:hover {
      background-color: #353535;
      color: #f3f3f3;
    }
    &-delete:hover {
      background: #fb4b53;
      color: #dfdfdf;
    }
  }

  &-divider {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    height: 1px;
    background-color: #3d3d3d;
  }

  &-main {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    height: -webkit-fill-available;

    & > div {
      margin-right: 12px;
      display: flex;
      align-items: center;
      font-size: 14px;
      &:nth-child(2) {
        width: 90%;
        min-width: 200px;
      }
    }
  }

  &-disabled {
    filter: brightness(0.4);
    &:hover {
      background-color: unset;
      color: unset;
      cursor: default;
    }
  }
}

.dropdown-multi-option {
  height: auto;
  width: 100%;
  padding-right: 0px;
  padding-left: 16px;
  label {
    width: 100%;
    display: inline-block;
  }
}

.dropdown-multi-option:hover {
  background-color: #353535;
  height: auto;
  width: 100%;
  padding-right: 0px;
  padding-left: 16px;
}

.dropdown-multi-option:first-child {
  margin-top: 16px;
}
.dropdown-multi-option:last-child {
  margin-bottom: 16px;
}
// END OF DROPDOWN

.dropdown-toggle .dropdown-toggle.w--open:focus {
  box-shadow: none;
}

// START OF EDITOR DROPDOWN - PREVIOUS STEP

.editor-previous-step-container > .dropdown-sort {
  height: 40px;
}

.editor-previous-step-container > .dropdown-sort > .dropdown-toggle {
  padding-left: 10px;
  font-size: 14px;
  line-height: 14px;
}

.editor-previous-step-container > .dropdown-sort > .dropdown-default {
  background: #555;
  border: none;
  &:hover {
    background: #616161;
  }
}

.editor-previous-step-container > .dropdown-sort > .dropdown-list {
  font-size: 12px;
  line-height: 14px;
  background: #555;
}

.editor-previous-step-container > .dropdown-sort > .dropdown-list.w--open {
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.editor-previous-step-container
  > .dropdown-sort
  > .dropdown-list
  > div
  > .dropdown-item {
  font-size: 14px;
  line-height: 14px;
  color: #f3f3f3;
  min-height: 40px;
  padding-left: 10px;
  &:hover {
    background: #6f6f6f;
  }
  &:last-child {
    border-bottom: 1px solid #303030;
  }
}

//START OF DROPDOWN FOR Terraform

.editor-terraform-container > div > .dropdown-sort {
  height: 32px;
}

.editor-terraform-container > div > .dropdown-sort > .dropdown-toggle {
  padding-left: 10px;
  font-size: 12px;
  line-height: 14px;
}

.editor-terraform-container > div > .dropdown-sort > .dropdown-default {
  background: #555;
  border: 1px solid #303030;
}

.editor-terraform-container > div > .dropdown-sort > .dropdown-list {
  font-size: 12px;
  line-height: 14px;
  background: #555;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
}

.editor-terraform-container > div > .dropdown-sort > .dropdown-list.w--open {
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.editor-terraform-container
  > div
  > .dropdown-sort
  > .dropdown-list
  > div
  > .dropdown-item {
  font-size: 12px;
  line-height: 14px;
  color: #f3f3f3;
  min-height: 32px;
  padding-left: 10px;
  &:hover {
    background: #6f6f6f;
  }
  &:last-child {
    border-bottom: 1px solid #303030;
  }
}
