.pw-strength {
  &-wrap {
    display: flex;
    width: 100%;
    height: 3px;
    padding-right: 1px;
  }
  &-block {
    height: 100%;
    &-weak {
      width: 33.3%;
      background-color: #fb4b53;
    }
    &-medium {
      width: 66.6%;
      background-color: #fed23a;
    }
    &-strong {
      width: 100%;
      background-color: #3dbb61;
    }
  }
  &-text {
    &-label {
      display: flex;
      color: #bebebe;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.32px;
      justify-content: space-between;
    }
    &-weak {
      color: #ff8389;
    }
    &-medium {
      color: #fed23a;
    }
    &-strong {
      color: #3dbb61;
    }
  }
  &-help {
    &-icon {
      display: inline-block;
      position: relative;
      justify-content: center;
    }
    &-text {
      display: flex;
      margin-left: 16px;
      flex-direction: column;
      padding: 14px;
      background: #f3f3f3;
      position: absolute;
      top: -400%;
      left: 140%;
      min-width: 15rem;
      border-radius: 0px;
      transition: opacity 0.5s;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
      color: #282828;
      border-radius: 3px;
    }
  }
  &-carrot {
    position: absolute;
    background-color: #f3f3f3;
    height: 14px;
    width: 14px;
    top: 13px;
    left: -6px;
    transform: rotate(45deg);
    overflow: hidden;
  }
}

.password-policy {
  justify-content: flex-start;
  align-items: center;
  &-text {
    font-size: 13px;
    padding-left: 8px;
    line-height: 20px;
  }
}

//Password Helper for mobile portrait
@media only screen and (max-width: 479px) {
  .pw-strength-text-label {
    flex-wrap: wrap;
  }
  .pw-strength-help-icon {
    display: block;
    width: 100%;
  }
  .pw-strength-help-text {
    position: relative;
    top: 12px;
    margin-bottom: 16px;
    left: 0;
    margin-left: 0px;
  }
  .pw-strength-carrot {
    display: none;
  }
}

//Password Helper for mobile landscape
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .pw-strength-text-label {
    flex-wrap: wrap;
  }
  .pw-strength-help-icon {
    display: block;
    width: 100%;
  }
  .pw-strength-help-text {
    position: relative;
    top: 12px;
    margin-bottom: 16px;
    left: 0;
    margin-left: 0px;
  }
  .pw-strength-carrot {
    display: none;
  }
}

//Password Helper for tablets
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pw-strength-text-label {
    flex-wrap: wrap;
  }
  .pw-strength-help-icon {
    display: block;
    width: 100%;
  }
  .pw-strength-help-text {
    position: relative;
    top: 12px;
    margin-bottom: 16px;
    left: 0;
    margin-left: 0px;
  }
  .pw-strength-carrot {
    display: none;
  }
}
