@import "../variables.scss";

.canvas-menu {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  overflow: auto;
  width: 324px;
  height: 100vh;
  max-height: none;
  padding: 90px 21px 70px;
  border-left: 1px solid $tertiaryBackground;
  background-color: $canvasBackground;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  z-index: 1;
  .canvas-menu-top {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 324px;
    height: 198px;
    border-bottom: 1px none #e8e8e8;
    background-color: #141414;
  }
  .side-panel-title-wrap {
    position: relative;
    top: -8px;
    display: flex;
    width: 100%;
    height: 68px;
    margin-top: -12px;
    margin-bottom: 12px;
    padding-bottom: 0px;
    align-items: flex-start;
    flex-direction: column;
    font-family: IBMPlexSans-Regular, sans-serif;
    .title-flex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 0 0 auto;
      .side-panel-title {
        color: #f2f2f2;
        font-size: $fontSizeHeading;
        line-height: 22px;
        font-weight: 400;
      }
    }
    .side-panel-desc {
      opacity: 0.6;
      color: #f3f3f3;
      font-size: $fontSizeMedium;
      line-height: 12px;
      font-weight: 400;
      margin-top: 5px;
    }
    .side-panel-tags {
      margin-top: 7px;
      display: inline-flex;
      .version {
        opacity: 0.8;
        color: #f3f3f3;
        font-size: $fontSizeMedium;
        font-weight: 400;
        margin-right: 8px;
        display: flex;
        align-items: center;
      }
      .side-panel-tag {
        height: 16px;
      }
    }
    .panel-delete-node {
      height: 20px;
      margin-left: 20px;
      font-size: $fontSizeMediumSmall;
    }
    .panel-close {
      position: absolute;
      right: 4px;
      top: 2px;
      width: 20px;
      height: 20px;
      background-image: url("https://assets.website-files.com/5cc3d0860871f792e924489c/5cc68ac67c5efa97fdd96f31_close.svg");
      background-position: 50% 50%;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .w-tabs {
    position: relative;
    background-color: $canvasBackground;
  }
  .w-tabs:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
  }
  .side-menu-tabs {
    position: relative;
    top: 0px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    background-color: #141414;
    :hover {
      color: #f3f3f3;
    }
    .tab-side {
      width: 33.33%;
      height: 40px;
      padding-right: 0px;
      padding-left: 0px;
      border-top: 3px solid transparent;
      border-bottom: 3px none transparent;
      background-color: #141414;
      opacity: 0.8;
      color: #f3f3f3;
      font-size: $fontSizeLarge;
      line-height: 17px;
      font-weight: 400;
      text-align: center;
      padding: 9px 30px;
      cursor: pointer;
      &.selected {
        border-top: 3px solid hsla(263.29411764705884, 100%, 50%, 1);
        border-bottom: 3px none hsla(264.2926829268293, 83.21%, 52.37%, 1);
        background-color: $canvasBackground;
        opacity: 1;
        color: #f3f3f3;
        font-weight: 400;
      }
    }
  }
  .canvas-content {
    .alert-description {
      background: $canvasBackground;
      position: relative;
    }
    .tab-panel {
      position: relative;
      display: none;
      &.active {
        display: block;
        opacity: 1;
        transition: opacity 300ms ease 0s;
      }
      .side-panel-section {
        margin-bottom: 24px;
        .side-panel-section-title-wrap {
          display: flex;
          width: 100%;
          height: 34px;
          margin-top: 16px;
          margin-bottom: 8px;
          align-items: center;
          .alert-panel-section-title {
            flex: 0 0 auto;
            opacity: 0.4;
            color: hsla(263.29411764705884, 0%, 100%, 1);
            font-size: $fontSizeSmall;
            font-weight: 400;
            text-transform: uppercase;
          }
          .alert-panel-divider {
            width: 100%;
            height: 1px;
            margin-left: 14px;
            background-color: #cfcfcf;
            opacity: 0.2;
          }
        }
        .side-panel-block-description {
          margin-bottom: 24px;
          margin-left: 0px;
          padding: 0px 10px 0px 0px;
          border-bottom: 1px none #8c8c8c;
          background-color: transparent;
          color: hsla(0, 0%, 95%, 0.9);
          font-size: $fontSizeLarge;
          font-weight: 400;
          cursor: not-allowed;
          span {
            line-height: 18px;
            display: block;
            text-align: left;
          }
        }
        .side-panel-section-form {
          padding-left: 12px;
          margin: 0 0 15px;
          text-align: left;
          .field-title {
            display: block;
            margin-bottom: 5px;
            color: #f3f3f3;
            font-weight: 400;
          }
          .text-field-input {
            display: block;
            width: 100%;
            padding: 8px 12px;
            font-size: $fontSizeLarge;
            line-height: 1.42857143;
            vertical-align: middle;
            border: 1px solid #cccccc;
            height: 40px;
            margin-top: 5px;
            margin-bottom: 14px;
            border-style: solid;
            border-width: 0px 0px 1px;
            border-color: #000 #000 #8c8c8c;
            color: #727272;
            font-weight: 400;
            background-color: $tertiaryBackground;
            cursor: not-allowed;
          }
        }
      }
      .table-header {
        display: flex;
        width: 100%;
        height: 40px;
        margin-top: 30px;
        justify-content: space-between;
        background-color: $tertiaryBackground;
        .table-header-title {
          display: flex;
          width: 40%;
          height: 100%;
          padding-left: 10px;
          align-items: center;
          flex: 0 0 auto;
          border-right: 1px solid #242424;
          color: hsla(0, 0%, 95%, 0.8);
          font-size: $fontSizeMediumLarge;
          font-weight: 400;
          text-transform: none;
          &:last-child {
            width: 60%;
            border-right: 0;
          }
        }
      }
      .table-row {
        display: flex;
        width: 100%;
        height: 40px;
        margin-top: 0px;
        align-items: center;
        border-bottom: 1px solid $tertiaryBackground;
        background-color: transparent;
        .table-row-info {
          display: flex;
          width: 40%;
          height: 100%;
          padding-left: 10px;
          align-items: center;
          flex: 0 0 auto;
          border-right: 1px none $tertiaryBackground;
          color: #f3f3f3;
          font-size: $fontSizeMediumLarge;
          font-weight: 400;
          &:last-child {
            position: relative;
            width: 60%;
            border-right: 0;
          }
          .cp-icon {
            position: absolute;
            right: 8px;
            width: 20px;
            height: 20px;
            background-image: url("https://assets.website-files.com/5cc3d0860871f792e924489c/5cc9041651a9ad039c63d72d_copy.svg");
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
          }
          .fail-red {
            color: #e71229;
            font-weight: 400;
          }
        }
      }
    }
  }
}
