.wait {
  &-wrapper {
    z-index: 99999;
    position: relative;
  }
  &__cover {
    position: fixed;
    width: 100%;
    overflow: hidden;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 200px;
      color: #a0a0a0;
      flex-direction: column;
    }
  }
  &-text {
    text-align: center;
    padding-bottom: 10px;
  }

  &-spinner {
    flex-direction: column;
  }
}
