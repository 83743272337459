.dc {
  &-container {
    display: flex;
    justify-content: space-between;
    height: 150px;
  }
  &-card {
    position: relative;
    padding: 24px;
    border: 1px solid #2f3034;
    border-radius: 6px;
    background-color: #282828;
    width: 19%;
    color: #fff;

    &-label {
      font-size: 16px;
      font-weight: 500;
      color: #f3f3f3;
      line-height: 20px;
    }
    &-value {
      font-size: 54px;
      line-height: 60px;
      font-weight: 300;
      padding-top: 16px;
    }
  }
}
