@import "../../scss/variables.scss";
#header {
  position: absolute;
}
#header .pro-sidebar {
  height: 100vh;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  flex-grow: 1;
  margin-top: -18px;
}
#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 70px;
  min-width: 70px;
}
#header .pro-sidebar-inner {
  background-color: #282828;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #fff;
  font-weight: 400;
  height: 40px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  color: #000;
  border-radius: 3px;
  padding-left: 6px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  min-width: 35px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  display: inline-block;
  & .pro-icon > img {
    height: 18px;
    width: 18px;
  }
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0px;
  cursor: pointer;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  padding: 1px;
  display: flex;
  align-items: center;
  & > img {
    height: 18px;
  }
}
#content .pro-menu .pro-menu-item > .pro-inner-item:hover {
  background-color: #3d3d3d;
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 14px;
  min-height: 40px;
  height: 40px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 14px;
  margin-right: 14px;
  border-radius: 6px;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: none;
}
#footer .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  margin-top: -19px;
  white-space: nowrap;
  overflow: visible;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-color: #3d3d3d;
  opacity: 1;
  opacity: 1;
  color: #f3f3f3;
  font-weight: 400;
  text-decoration: none;
}
#header .logo {
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

.logo-small {
  margin-bottom: 24px;
  margin-top: 24px;
  margin-left: 20px;
  cursor: pointer;
}

.logo-big {
  margin-bottom: -21px;
  margin-top: -21px;
  margin-left: 19px;
  cursor: pointer;
}

.open-close {
  position: absolute;
  right: -17px;
  transition: right 0.3s;
  top: 26px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 17px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #2e2e2e;
  border-radius: 0px 2px 2px 0px;
  background-color: #282828;
  cursor: pointer;
  z-index: 5;
}

.line-open-primary {
  width: 1px;
  height: 22px;
  background-color: #8b8b8b;
}

.line-open-secondary {
  margin-left: 2px;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent;
}

.lm {
  &-version {
    margin-bottom: 20px;
    font-size: 12px;
    opacity: 0.7;
    padding-left: 15px;
  }
}

.menu-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

ul.nav-menu {
  background-color: $secondaryBackground;
  margin-bottom: 0px;
  padding-left: 0px;
  font-size: $fontSizeLarge;
  color: $secondaryFontColor;
  letter-spacing: 0.16px;
  list-style-type: none;
}
.nav-menu__item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.nav-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 45px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 3px solid transparent;
  -webkit-transition: all 90ms ease;
  transition: all 90ms ease;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  color: $secondaryFontColor;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
  &-support {
    padding-left: 16px;
  }
}

.w--not-selected {
  border-left: 3px solid transparent;
  &:hover {
    background-color: $tertiaryBackground;
  }
}
.w--current {
  border-left: 3px solid $primaryAccentColor;
  background-color: $tertiaryBackground;
  opacity: 1;
  color: $secondaryFontColor;
  font-weight: 400;
  text-decoration: none;
}

.icon {
  margin-right: 10px;
  background-position: 7% 50%;
  background-size: 18px 17px;
  background-repeat: no-repeat;
  opacity: 1;

  &__support {
    background-position: 90% 50%;
    background-size: 12px;
  }

  &__users {
    background-image: url(../../assets/images/icons/icon-users.svg);
  }

  &__external {
    background-image: url(../../assets/images/icons/icon-external.svg);
  }
}

.bottom-section {
  margin-bottom: 5rem;
}

.divider-wrap {
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #3d3d3d;
}

.user-details {
  padding: 0.9rem 1rem;
  display: inline-block;
  .username {
    flex-basis: 90%;
    color: #f3f3f3;
    font-weight: 400;
    letter-spacing: 0.16px;
    font-size: 15px;
  }
  .email {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0.3rem;
    letter-spacing: 0.16px;
    opacity: 0.5;
    color: #f3f3f3;
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.current-plan {
  &__info {
    padding: 0.5rem 1rem 1.5rem;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
  }
  &__bar-wrap {
    display: flex;
    width: 100%;
    height: 8px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #656565;
    &__bar-use {
      width: 50%;
      height: 100%;
      border-radius: 4px;
      background: linear-gradient(45deg, #5ca8ff, #9363ff);
      &.red {
        background: #ff5b5b;
      }
    }
  }
  &__upgrade-link {
    margin-top: 8px;
    color: #5ca8ff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
}

.avtarBackground {
  .sb-avatar__text {
    background: #733ebb !important;
  }
}
.pro-icon {
  animation: swing ease-in-out 0s 0 alternate;
}
