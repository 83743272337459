.uo {
  &-header {
    height: 240px;
    padding-left: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #252525;
    background-color: transparent;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#171717),
        to(transparent)
      ),
      radial-gradient(
        circle farthest-side at 100% 100%,
        #4e2399,
        #44279a 12%,
        #1f2978 24%,
        #171717
      );
    background-image: linear-gradient(180deg, #171717, transparent),
      radial-gradient(
        circle farthest-side at 100% 100%,
        #4e2399,
        #44279a 12%,
        #1f2978 24%,
        #171717
      );
  }

  &-title {
    color: #fff;
    font-size: 1.875rem;
    line-height: 2.675rem;
    font-weight: 700;
    letter-spacing: 0.32px;
  }

  &-subtitle {
    margin-top: 0.5rem;
    color: #f3f3f3;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }

  &-wrapper {
    padding: 3rem;
  }

  &-checklist {
    max-width: 700px;

    &-wrapper {
      background-color: #282828;
      padding: 1rem;
    }

    &-header {
      color: #fff;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 300;
    }

    &-description {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      color: #f3f3f3;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;

      &-left {
        display: flex;
      }

      &-icon {
        margin-right: 0.8rem;
        width: 24px;
        height: 24px;
      }

      &-title {
        color: #fff;
        font-size: 16px;
        line-height: 23px;
      }

      &-description {
        padding-top: 4px;
        color: #bebebe;
        font-size: 13px;
      }

      &-right {
        display: flex;
        align-items: center;
      }
    }

    &-btn {
      height: 40px;
      padding: 8px 13px;
      -webkit-transition: all 100ms ease;
      transition: all 100ms ease;
      color: #7cb9ff;
      border: 3px solid transparent;
      box-shadow: inset 0 0 0 1px transparent;
      line-height: 1.25rem;
      letter-spacing: 0.16px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background-color: #3d3d3d;
      }
    }
  }

  &-basics {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &-card {
      width: 240px;
      max-width: 250px;
      min-height: 285px;
      margin-right: 32px;
      padding: 16px 16px 18px;
      margin-bottom: 1.5rem;
      border-style: none;
      border-width: 1px;
      border-color: #3d3d3d;
      background-color: #282828;
      -webkit-transition: all 70ms ease;
      transition: all 70ms ease;
      text-decoration: none;
      position: relative;

      &-image {
        width: 64px;
        height: 64px;
        margin-bottom: 1rem;
        background-image: url("https://assets.website-files.com/5f6a5fce39985a04f98f9115/602ea0b90f658953537f1915_placeholder-img.svg");
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &-title {
        max-width: 170px;
        margin-bottom: 1rem;
        color: #fff;
        font-size: 1rem;
        line-height: 1.6rem;
      }

      &-description {
        color: #bebebe;
        font-size: 13px;
        font-weight: 300;
      }

      &-btn {
        cursor: pointer;
        margin-top: 2rem;
        height: 40px;
        width: fit-content;
        padding: 8px 16px;
        border-width: 2px;
        background-color: #6300ff;
        -webkit-transition: all 100ms ease;
        transition: all 100ms ease;
        color: #fff;
        line-height: 1.25rem;
        letter-spacing: 0.16px;
        text-decoration: none;
        position: absolute;
        bottom: 1rem;
      }
    }
  }
}
