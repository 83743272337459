.overflow-menu {
  &-container {
    position: absolute;
    top: 10px;
    right: 10px;
    min-height: 100px;
  }
  &-icon {
    float: right;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 8px;
    right: 10px;
    background-image: url("../../../assets/images/icons/icon-overflow.svg");
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      background-color: #3d3d3d;
    }
  }
  &-items {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 150px;
    background: #3d3d3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
    & > div {
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;
    }
    & > div:last-child {
      border-bottom: 1px solid transparent;
    }
  }
}
