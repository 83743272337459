.connector-integration-add {
  height: 75%;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
