@import "../../../scss/variables.scss";

.tabs-wrap {
  width: 100%;
  box-sizing: border-box;
}

.runbook-details-tabs > .tabs-wrap {
  border-bottom: 2px solid #252525;
}

.snippet-details-tabs > .tabs-wrap {
  border-bottom: 2px solid #252525;
}

.w-100.tabs-wrap {
  border-bottom: 2px solid #252525;
}

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  position: relative;

  &__nav-link {
    display: flex;
    &-workflow_count {
      margin: 0 0.5rem;
    }
  }
}

.tabs__nav-item-dark {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10rem;
  height: 42px;
  margin-bottom: -2px;
  margin-right: 0.125rem;
  padding: 0.5rem 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  font-family: IBMPlexSans-Regular, sans-serif;
  color: #bebebe;
  font-weight: 400;
  letter-spacing: 0.32px;
}

.tabs__nav-item-dark-active {
  margin-left: 0rem;
  border-bottom-color: $tabActiveBorderColor;
  background-color: transparent;
  font-family: IBMPlexSans-Medium, sans-serif;
  color: #fff;
  font-weight: 400;
  border-left: 0px;
  width: 250px;
}

.tabs__nav-item-dark-inactive {
  color: $tabInactiveBorderColor;
  font-weight: 400;
  letter-spacing: 0.32px;
  &:hover {
    border-bottom-color: $tabInactiveHoverBorderColor;
    color: $tabInactiveHoverColor;
  }
  width: 250px;
}

.w-tab-menu {
  cursor: pointer;
}

.tab-content {
  position: absolute;
  left: 0px;
  width: 100%;
  cursor: default;
  padding-bottom: 3rem;
}

.tab-content__actions-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hidden {
  display: none;
}
