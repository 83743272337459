.terraform {
  &-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    &-label {
      font-size: 0.75rem;
      margin-bottom: 0.2rem;
      filter: brightness(0.7);
    }
  }
}

.overflow-y {
  overflow-y: auto;
}

.terraform-modal-content {
  margin-bottom: 7rem;
}
