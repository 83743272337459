.copy-outerwrap {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
}

.icon-cell {
  position: relative;
}

.icon-copy-wrapper {
  border: 1px solid #8c8c8c;
  width: 32px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.icon-copy {
  width: 30px;
  height: 2rem;
  background-image: url("../../../assets/images/icons/icon-copy.svg");
  background-color: #3d3d3d;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 16px;
}

.message {
  margin-right: 16px;
  font-size: 13px;
}

.__json-pretty__ {
  color: #abb2bf;
  font-family: IBMplexmono, monospace;
}
.json-pretty {
  .__json-pretty__ {
    overflow: auto;
    background: #252525;
  }
  .__json-pretty-error__ {
    background: #252525;
    overflow: auto;
  }
  .__json-key__ {
    color: #e06b75;
  }
  .__json-string__ {
    color: #98c379;
  }
  .__json-boolean__ {
    color: #61afef;
  }
  .__json-value__ {
    color: #c678dd;
  }
}

.json-icon-tooltip-container {
  width: max-content !important;
}
