.task-assignment {
  &-content {
    overflow: unset;
    &-container {
      overflow: unset;
    }
  }
  &-wrapper {
    width: 85%;
  }
}
