.logo-wrap {
  position: relative;
  width: 112px;
  height: 18px;
  background-image: url("../../assets/images/logo/fylamynt-text-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 24px;
  margin-top: 32px;
  margin-left: 16px;
}

.logo-wrap-editor {
  position: relative;
  width: 112px;
  height: 18px;
  background-image: url("../../assets/images/logo/fylamynt-logo.svg");
  opacity: 0.6;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 24px;
  margin-top: 32px;
  margin-left: 16px;
}

.editor-menu-fylamynt-logo {
  margin-left: 13px;
  margin-top: 9px;
  width: 20px;
  height: 24px;
  & g {
    fill: $svgFill1;
  }
}
