/* Don't delete these */
$purple: #6300ff;
$soft-white: #efefef;
$orange: #fd9b3f;
$green: #4de357;
$lightblue: #59cdff;
$gray: #6f6f6f;

$fontSizeSmall: 10px;
$fontSizeMediumSmall: 11px;
$fontSizeMedium: 12px;
$fontSizeMediumLarge: 13px;
$fontSizeLarge: 14px;
$fontSizeHeadingSmall: 16px;
$fontSizeHeading: 18px;
$fontSizeHeadingLarge: 20px;

/* Modify below this line */

$fontFamily: IBMPlexSans-Regular, sans-serif;

$primaryBackground: #171717;
$primaryFontColor: #bebebe;
$secondaryBackground: #282828;
$secondaryFontColor: #f3f3f3;
$tertiaryFontColor: #6300ff;
$tertiaryBackground: #3d3d3d;
$tertiaryAccentColor: #444444;
$tertiarySecondaryBackground: #161616;

$bodyFontSize: 14px;
$bodyFontColor: $primaryFontColor;
$bodyBackground: $primaryBackground;

$h4FontColor: #f3f3f3;

$primaryAccentColor: #6300ff;
$primaryAccentColorHover: #6208f1;
$primaryAccentColorAlt: #5203cf;
$secondaryAccentColor: #282828;

$warnBackground: #ff5b5b;
$warnFontColor: #fff;
$warnHoverBackground: #fa4f4f;

/* EDITOR */
$canvasBackground: #262626;
$canvasPrimaryBackground: #303030;
$canvasPrimaryAccent: #666;
$canvasSecondaryBackground: #4d4d4d;
$webkitScrollHover: #454545;
$canvasBorder1: #1d1d1d;
$primaryNodeColor: #00c0ff;
$portSelectedColor: #c0ff00;
$portBackground: rgba(255, 255, 255, 0.1);
$defaultNodeBackground: #3c3d3c;
$defaultPortsBackground: rgba(70, 70, 70, 0.8);
$nodeFontColor: #fff;
$nodeBorderColor: #000;
$defaultPortsBorderColor: #333;
$defaultPortsFontColor: #fff;
$defaultLinkBackground: rgba(255, 255, 255, 0.5);
$jsonContentButtonBorderColor: #fff;
$jsonContentButtonBorderHover: yellow;
$srdPrimaryBackground: #3c3c3c;
$srdButtonFontColor: #fff;
$primaryButtonBackground: $primaryAccentColor;
$primayButtonHoverBackground: $primaryAccentColorAlt;
$secondaryButtonBackground: #606060;
$secondaryButtonBackgroundHover: #777;
$ruleListBorderColor: #777;
$ruleListFontColor: hsla(0, 0%, 95.3%, 0.5);
$ruleListHoverBackground: rgba(94, 94, 94, 0.49);
$stepContainerPrimaryBackround: #333;
$ruleInputBackground: #555;

/* FORMS */
$textField1FontColor: #333333;
$accordionPrimaryBackground: #2c2c2c;
$accordionBorderColor1: #2e2e2e;
$accordionAccentColor: #f3f3f3;
$accordionFontColor: #f3f3f3;
$accordionAccent: #494949;
$linkHoverBackground: #888;
$textInputPrimaryColor: #d3d3d3;

/* RADIO BUTTONS */
$radioPrimary: $primaryAccentColor;
$checkmarkBackground: #eee;
$checkmarkBackgroundHover: #aaa;
$checkmarkChecked: #6300ff;
$checkmarkAfter: #fff;
$checkmarkUnchecked: #eee;

/* BUTTONS */
$btnPrimaryFontColor: #fff;
$btnSecondaryBackground: #6f6f6f;
$btnSecondaryBorderColor: #6f6f6f;
$btnSecondayBackground: #6f6f6f;
$btnSecondaryFontColor: #fff;
$btnSecondaryBackgroundHover: #606060;
$btnSecondaryActiveFontColor: #f3f3f3;
$btnSecondaryActiveBackground: #3d3d3d;
$btnSecondaryFocusBorderColor: #f3f3f3;

/* TABS */
$tabActiveBorderColor: #6300ff;
$tabInactiveBorderColor: #bebebe;
$tabInactiveHoverBorderColor: #6f6f6f;
$tabInactiveHoverColor: #f3f3f3;

/* MODALS */
$primaryModalButtonBackground: #6300ff;
$primaryModalButtonFontColor: #f3f3f3;
$modalLabelFontColor: #353535;
$modalTitleColor: #171717;
$modalBorderColor: #353535;
$modalFormBackground: #f3f3f3;
$modalFormFontColor: rgba(53, 53, 53, 0.5);
$modalTagBackground: #e4e4e4;
$modalTagFontColor: #171717;
$modalCancelButtonFontColor: #171717;

/* TAGS */
$primaryTagBackground: #3d3d3d;
$primaryTagFontColor: #f3f3f3;
$primaryTagLightBackground: #3d3d3d;
$primaryTagLightFontColor: hsla(0, 0%, 95.3%, 0.7);

/* DATATABLE */
$table-header-color: #3d3d3d;
$table-header-border: #3d3d3d;
$table-border-color: #555;
$table-bg-color: #252525;
$table-border: transparent;
$dark-bg-color: #202020;
$row-bg: transparent;

/* EXECUTIONS */
$executionsBorderRight: #3d3d3d;
$executionsSidebarBackground: #161616;
$executionsStepBorderColor: hsla(0, 0%, 100%, 0.1);
$executionsStepBackground: hsla(0, 0%, 100%, 0.05);
$executionsStatusLoadingBorder: #7d7d7d;
$executionsTitle: #555;

/* SELECT */
$selectBackgroundColor: #282828;
$selectFontColor: #fefefe;
$selectTitleBorderColor: #8c8c8c;
$selectOptionBorderColor: #464646;
$selectOptionBackground: #353535;

/* MISC */
$svgFill1: #999;
$svgFill2: #fff;
$deleteHoverBackground: #ff5b5b;
$srdDemoBackground: #000;
