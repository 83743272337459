.rs {
  .rs__placeholder {
    color: #f3f3f3;
    opacity: 0.5;
  }
  &__clear-indicator {
    color: #f3f3f3 !important;
    cursor: pointer;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  &-dropdown-icon {
    padding: 1px;
    &-delete {
      margin-left: auto;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    &-collapsed {
      transform: rotate(0deg);
      transition: transform 0.2s ease;
    }
    &-expanded {
      transform: rotate(180deg);
      transition: transform 0.2s ease;
    }
  }

  &-option {
    font-size: 14px;
    overflow-x: auto;
    height: 40px;
    cursor: pointer;
    padding: 0 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:active {
      background: #545454;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #f3f3f3;
    }
    &-selected {
      color: #84bdff;
      background: rgba(255, 255, 255, 0.1);
      &:hover {
        color: #f3f3f3;
      }
    }
    &-inner {
      white-space: nowrap;
    }
    &-static {
      background: #3d3d3d;
      color: #9bbfff;
      height: 36px;
      font-size: 12px;
      &:hover {
        background: #3d3d3d;
        color: #9bbfff;
      }
    }
  }

  &-control {
    display: flex;
    align-items: center;
    background: #555;
    border-radius: 0;
    height: 40px;
    min-height: 2rem;
    font-size: 14px;
    box-shadow: 0;
    &:hover {
      background: #616161;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px 2px #f3f3f3;
    }
  }

  &-value {
    &-container {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 2px 14px;
      overflow: hidden;
    }

    &-single {
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 2px;
      margin-right: 2px;
      max-width: calc(100% - 8px);
      overflow: hidden;
      box-sizing: border-box;
      color: #f3f3f3;
    }
  }

  &-menu {
    position: absolute;
    background: #545454;
    z-index: 3;
    margin-top: -1px;
    width: 100%;
    border-radius: 0;
    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid #f3f3f3;
    &-list {
      max-height: 10rem;
      overflow-y: auto;
    }
  }
}

.required-unfulfilled {
  border: 2px solid #f55;
}
