@import "@scss/variables.scss";

.execution-details {
  box-sizing: border-box;
  /* execution-details-wrap */
  &-wrap {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-left: 16px;
    margin-bottom: 32px;
  }
  &-block {
    /* execution-details-block-wrap */
    &-wrap {
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
      & a {
        color: #fff;
        text-decoration: underline;
      }
    }
    &-div {
      width: 46%;
      height: auto;
      max-width: 540px;
    }
  }
  /* execution-details-status */
  &-status {
    &-wrap {
      position: relative;
      display: flex;
      width: 60%;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 24px;
      align-items: center;
    }
    &-block {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      height: 26px;
      padding: 5px;
      padding-left: 8px;
      padding-right: 8px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: $fontSizeSmall;
      font-weight: 400;
      letter-spacing: 0.32px;
      text-transform: uppercase;
    }

    &-green {
      background-color: rgba(61, 187, 97, 0.23);
      color: #3dbb61;
    }
  }
  &-table-col {
    padding: 16px 5px 10px 16px;
    height: 50px;
    &-left {
      width: 30%;
      background: $table-header-color;
      border-bottom: 1px solid #181818;
      border-right: 2px solid #181818;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &-right {
      width: 70%;
      background: $table-bg-color;
      border-bottom: 1px solid $dark-bg-color;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }
  }
}
.details {
  &-wrap {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    margin-top: 24px;
    padding-left: 16px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &__block-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  &-block {
    width: 50%;
    height: auto;
    max-width: 540px;
  }
}

/* STEPS */
.stepper {
  &-line {
    position: absolute;
    left: 11px;
    top: 40px;
    bottom: 0px;
    width: 1px;
    height: 100%;
    background-color: #6f6f6f;
  }
  &-wrap {
    position: relative;
    display: flex;
    padding-left: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
  &-child {
    margin-top: 40px;
  }
  &-content {
    display: flex;
    width: 100%;
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
  }
  &-circle {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    border-style: solid;
    border-width: 1px;
    border-color: #6f6f6f;
    border-radius: 100%;
    background-color: #171717;
    font-family: Inconsolata, monospace;
    font-size: $fontSizeMediumLarge;
    text-align: center;
  }
  &-accordion-label {
    display: flex;
    width: 100%;
    height: 32px;
    padding-right: 12px;
    padding-left: 12px;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: rgba(243, 243, 243, 0.7);
    font-size: $fontSizeMedium;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: -4px;
    margin-bottom: 12px;
  }

  &-table-container {
    box-sizing: border-box;
    width: 98%;
    margin-left: 20px;
  }
}
.meta-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 6px;
  padding-bottom: 6px;
}

.meta-text {
  margin-left: 5%;
  padding-right: 10%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  opacity: 0.9;
  color: #f3f3f3;
  font-size: $fontSizeMediumLarge;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 20px;
}

.meta-title {
  max-width: 140px;
  min-width: 110px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  opacity: 0.8;
  color: #bebebe;
  font-size: $fontSizeMediumLarge;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 20px;
}

.step-table {
  &-container {
    display: table;
    width: 100%;
  }
  &-row {
    display: table-row;
    &-cell {
      display: table-cell;
      background: $table-bg-color;
      vertical-align: top;
      height: 32px;
      min-width: 280px;
      line-height: 32px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 12px;
      padding-bottom: 4px;
      &:nth-child(2) {
        min-width: 160px;
      }
    }
  }
  &-header {
    display: table-row;
    background: $table-header-color;
    &-cell {
      display: table-cell;
      padding: 5px;
      padding-left: 16px;
      height: 32px;
      line-height: 32px;
    }
  }
}
