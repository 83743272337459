@import "src/scss/variables.scss";

.section {
  margin: 0 50px;
  color: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 5rem 3rem 2rem 3rem;

    .header-text {
      font-size: 1.875rem;
    }
  }

  .plans {
    .plans-section {
      padding: 2rem 3rem;
      display: flex;
      justify-content: space-between;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;

      .pricing-plan {
        padding: 1rem 2rem 2rem 2rem;
        width: 22%;
        background-color: $secondaryBackground;

        .plan-type {
          padding: 10px;
          font-size: 20px;
          text-align: center;
        }
        .description {
          text-align: center;
        }
        .price {
          display: flex;
          margin-top: 1.5rem;
          margin-bottom: 2rem;
          font-size: 40px;
          justify-content: center;
        }
        .text {
          margin-left: 4px;
          margin-top: 20px;
          font-size: 16px;
          color: hsla(0, 0%, 95.3%, 0.8);
        }
        .workflow-details-section {
          padding-bottom: 2rem;

          .details {
            margin-bottom: 12px;
            display: flex;
          }
        }
        .upgrade-btn {
          padding: 8px 13px;
          height: 40px;
          justify-content: center;
          width: 100%;
          margin: 0 auto;
          max-width: none !important;
        }
        .current-selected {
          cursor: auto;
          background-color: transparent;
          box-shadow: inset 0 0 0 1px #777;
        }
        .tick-icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          background-image: url("../../../../assets/images/icons/icon-tick.svg");
          background-position: 50% 50%;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
