.cw-form {
  &-container {
    height: auto;
  }
  &-title {
    padding: 0 1rem 2rem;
  }
  &-panel {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  &-left {
    margin-right: 2rem;
    padding: 1.5rem;
  }
}
