.header {
  &-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #171717;
    background-color: #282828;
    padding: 0 32px;
    height: 3.5rem;
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 54.6%;
      padding-left: 28px;
      height: 100%;
    }
  }
}
