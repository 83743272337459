.chartContainer {
  display: flex;
  justify-content: space-between;
}
.remove-max-height {
  max-height: none;
}
.rdrDateRangePickerWrapper {
  float: right;
  position: absolute;
  z-index: 4;
  top: 2.5rem;
}
.rangeBtnContainer {
  height: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.rangeBtn {
  width: 12rem;
  justify-content: center;
  padding: 0 8px;
  align-items: center;
  background: #282828;
  border-bottom: 1px solid #8c8c8c;
  color: #f3f3f3;
  &:hover {
    background: #353535;
  }
  &:focus {
    background: #353535;
  }
}
.btn-primary:focus {
  box-shadow: none;
}
