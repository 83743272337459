@import "../../../scss/variables.scss";

form {
  display: block;
  margin-top: 0em;
  position: relative;
}

.form-cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  box-sizing: border-box;
  font-size: $fontSizeLarge;
}

.form-center {
  width: 250px;
  height: 100px;
  margin: 0 auto;
  margin-top: 25%;
}

.btn-hidden {
  display: none;
}

.form-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
}

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 0.32px;
  cursor: default;
}

.label-custom {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 0.32px;
  cursor: default;
}

.form-error {
  color: indianred;
}
