@import "../../../scss/variables.scss";

.data-table-container {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0px;
  background: inherit;
  position: relative;
  color: inherit;

  & header {
    margin: 0;
    padding: 0;
    max-height: 0;
    visibility: hidden;
    display: none;
  }

  & a {
    display: block;
    color: #5ca8ff;
    padding: 3px 8px;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: $fontSizeMedium;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background-color: #3d3d3d;
    }
  }
  & span.tag {
    display: block;
    background-color: #3d3d3d;
    padding: 1px 8px;
    line-height: 23px;
    margin-left: 0px;
    margin-right: 8px;
    word-break: keep-all;
  }
  input[type="checkbox"][name="select-all-rows"] {
    margin-left: 6px;
  }
}
.rdt_TableBody {
  overflow-y: unset !important;
}
.rdt_TableHeadRow {
  min-height: 50px;
  border-top: 1px solid transparent;
  background-color: #3d3d3d;
  border-bottom: 1px solid transparent;
}
.rdt_TableCol_Sortable {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 16px;
  font-weight: 400;
  &:hover {
    color: #f3f3f3;
    &:not(:focus) {
      color: #f3f3f3;
    }
  }
  &:focus {
    color: #f3f3f3;
  }
}

.rdt_TableRow:not(:last-of-type) {
  border-bottom-color: #3d3d3d;
}

.rdt_Pagination {
  min-height: 48px;
  background-color: #282828;
  border-top: 2px solid #3d3d3d;
  padding: 0;
}

#pagination-first-page {
  height: 48px;
  width: 48px;
  border-left: 1px solid #3d3d3d;
  border-radius: 0;
  transition: 0s;
  &:focus {
    box-shadow: inset 0 0 0 2px #f3f3f3;
    background-color: transparent;
  }
}
#pagination-previous-page {
  height: 48px;
  width: 48px;
  border-left: 1px solid #3d3d3d;
  border-radius: 0;
  transition: 0s;
  &:focus {
    box-shadow: inset 0 0 0 2px #f3f3f3;
    background-color: transparent;
  }
}
#pagination-next-page {
  height: 48px;
  width: 48px;
  border-left: 1px solid #3d3d3d;
  border-radius: 0;
  transition: 0s;
  &:focus {
    box-shadow: inset 0 0 0 2px #f3f3f3;
    background-color: transparent;
  }
}
#pagination-last-page {
  height: 48px;
  width: 48px;
  border-left: 1px solid #3d3d3d;
  border-radius: 0;
  transition: 0s;
  &:focus {
    box-shadow: inset 0 0 0 2px #f3f3f3;
    background-color: transparent;
  }
}
