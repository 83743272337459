@import "../../../scss/variables.scss";

.breadcrumb-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0 1rem;
}

.breadcrumbs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: IBMPlexSans-Regular, sans-serif;
}

.breadcrumb-link {
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: all 90ms ease;
  transition: all 90ms ease;
  height: 20px;
  color: #bebebe;
  font-size: $fontSizeMediumLarge;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.16px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb-pike {
  padding-left: 5px;
  padding-right: 6px;
  font-size: 14px;
  display: inline-block;
}
