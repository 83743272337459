.sidebar {
  padding: 1.5rem;
  width: 33%;
  min-width: 262px;
  border-right: 1px solid #171717;
}

.modal-header-sidebar {
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #f3f3f3;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0px;
}

.modal-container-sidebar {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 850px;
  background-color: #282828;
  color: #f3f3f3;
  top: -5vh;
  opacity: 0;
  margin: 0 auto;
  z-index: 1000;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  height: 94vh;
  max-height: 730px;
}

.modal-content-container-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.modal-content-sidebar {
  height: 75%;
  overflow: auto;
  width: 100%;
  max-width: unset;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.form-header {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 1.5rem;
  .title {
    color: #f3f3f3;
    font-size: 1rem;
    line-height: 1.625rem;
    letter-spacing: 0px;
    margin-bottom: 5px;
  }
  .description {
    color: #bebebe;
    font-size: 13px;
  }
}

.modal-footer__wizard {
  height: 5rem;
}

.modal-buttons-footer {
  &__wizard {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    position: relative;
    & > button {
      max-width: 15rem !important;
    }
  }
  &__button {
    width: 100%;
    max-width: 16.5rem;
  }
}
