.error-container {
  height: 100vh;
  width: 350px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  line-height: 1.5rem;
  button {
    height: 50px;
    margin-top: 24px;
    background-color: #6300ff;
    font-size: 15px;
    font-weight: 400;
    border: none;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #5e09e4;
    }
  }
}
.error-container > .message-container {
  color: #ccc;
}
