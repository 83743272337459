.ps {
  &-container {
    width: 100%;
    display: flex;
    max-width: none;
    margin-top: 2rem;
    margin-bottom: 0rem;
    border-left: 3px solid #3eba61;
    background-color: #3d3d3d;
    height: auto;
    min-height: 3rem;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    flex-direction: row;
    &__inner-wrap {
      display: flex;
      align-items: center;
    }
    &-cross {
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: #f3f3f3;
      margin-right: 8px;
      padding: 8px 4px;
      &:hover {
        background-color: #524f4f;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
    }
  }
}
