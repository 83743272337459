.header-nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: auto;
  height: 62px;
  width: 100vw;
  z-index: 100;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $tertiaryBackground;
  background-color: $tertiarySecondaryBackground;
  .hidden {
    display: none;
  }
  a.back-link {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    right: auto;
    bottom: 0;
    width: 50px;
    height: 100%;
    margin-right: 0px;
    background-image: url("https://assets.website-files.com/5cc3d0860871f792e924489c/5cc6bc2cb2ea2e3872375cde_back-carrot.svg");
    background-position: 50% 60%;
    background-size: 20px;
    background-repeat: no-repeat;
    opacity: 1;
    transition: background-color 200ms ease;
  }
  .meta {
    position: absolute;
    left: 0;
    top: 0;
    right: auto;
    bottom: 0;
    display: flex;
    width: auto;
    height: 100%;
    margin-left: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 0 0 auto;
    .meta-row {
      display: flex;
      width: auto;
      height: 24px;
      align-items: center;
      flex: 0 0 auto;
      font-family: IBMPlexSans-Regular, sans-serif;
      .desc-title {
        margin-right: 12px;
        color: $secondaryFontColor;
        font-size: $fontSizeHeadingSmall;
        line-height: 28px;
        font-weight: 600;
      }
      .tag {
        margin: 0 0 0 5px;
        height: 20px;
      }
      .desc-text {
        opacity: 0.5;
        color: $secondaryFontColor;
        font-size: $fontSizeMedium;
        line-height: 24px;
        font-weight: 400;
      }
      .runbook-status {
        justify-content: center;
        flex: 0 0 auto;
        color: #f2f2f2;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        &.draft {
          height: 21px;
          margin-top: 1px;
          margin-left: 5px;
          padding: 0px 7px;
          border-style: solid;
          border-width: 1px;
          border-color: #ababab;
          border-radius: 20px;
          background-color: transparent;
          color: $primaryFontColor;
          font-size: $fontSizeMediumSmall;
          line-height: 19px;
          font-weight: 400;
        }
      }
    }
  }
  .utility-wrap {
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
    .utility-wrap-more {
      position: relative;
      flex-direction: row;
      background-image: none;
      cursor: pointer;
      display: flex;
      width: 52px;
      height: 51px;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      background-position: 50% 60%;
      background-size: 26px;
      background-repeat: no-repeat;
      transition: background-color 200ms ease;
      .proxy-bg {
        display: flex;
      }
      .dot {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: $secondaryFontColor;
        &.dot-middle {
          margin: 0px 6px;
        }
      }
    }
    .utility-wrap-notification {
      display: flex;
      width: 52px;
      height: 51px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      background-image: url("https://assets.website-files.com/5cc3d0860871f792e924489c/5cc6884bb2ea2e96cc36abdc_bell.svg");
      background-position: 50% 60%;
      background-size: 26px;
      background-repeat: no-repeat;
      transition: background-color 200ms ease;
    }
  }
  .center-wrap {
    display: flex;
    height: 61px;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    font-family: IBMPlexSans-Regular, sans-serif;
    a.center-wrap-btn {
      position: relative;
      display: flex;
      width: 61px;
      height: 44px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: background-color 200ms ease;
      color: #f2f2f2;
      font-weight: 400;
      text-decoration: none;
      margin: 0 2px;
      &.btn-active {
        position: relative;
        background-color: $tertiaryBackground;
        cursor: pointer;
      }
      .build-icon {
        width: 32px;
        height: 32px;
        margin-top: 8px;
        background-image: url("https://assets.website-files.com/5cc3d0860871f792e924489c/5cc6c3db0871f70ec52c1620_edit.svg");
        background-position: 50% 50%;
        background-size: 28px;
        background-repeat: no-repeat;
      }
      .run-icon {
        width: 28px;
        height: 28px;
        margin-top: 2px;
        background-image: url("https://assets.website-files.com/5cc3d0860871f792e924489c/5cc85bd186283777e5781aba_run.svg");
        background-position: 50% 50%;
        background-size: 23px;
        background-repeat: no-repeat;
        opacity: 0.5;
      }
    }
  }
}
