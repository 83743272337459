.description {
  &-header {
    color: #f3f3f3;
    background-color: #171717;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 1rem;
    padding-left: 16px;
  }

  &-overview {
    margin-bottom: 2.5rem;
  }
  &-summary {
    color: #f3f3f3;
    background-color: #171717;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    padding-left: 16px;
  }
  &-table {
    .rdt_TableHeadRow {
      background: transparent;
      border-bottom: 0.01rem solid #3d3d3d;
    }
    .rdt_TableCol_Sortable {
      font-weight: 600;
    }

    .rdt_TableRow {
      background: transparent;
      border-bottom: 0.01rem solid #3d3d3d;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.3125rem;
    }
  }
}
