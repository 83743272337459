.hwn {
  &-container {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #171717;
    padding: 0 32px;
    height: 3.5rem;
    background-color: #282828;
    align-items: center;
  }
}
.navigation-link {
  position: absolute;
  left: 60px;
  font-size: 14px;
}
