.profile {
  &-title {
    font-size: 1.875rem;
    line-height: 2.675rem;
    font-weight: 400;
    letter-spacing: 0.32px;
    margin-bottom: 2rem;
    color: #fff;
  }
  &-panel {
    display: flex;
    width: 100%;
  }
  &-left {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    width: 55%;
  }
  &-right {
    display: flex;
    flex-direction: column;
    width: 40%;
    &-field-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
  &-submit-btn {
    height: 3rem;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6300ff;
    color: #fff;
    border: 2px solid #6300ff;
    cursor: pointer;
  }
}

.btn-download {
  margin-right: 1rem;
  height: 2.5rem;
  width: 12rem;
  line-height: 20px;
  padding: 10px 0px 0px 13px;
  cursor: pointer;
  background-color: #6f6f6f;
  color: #fff;
  background-image: url("../../assets/images/icons/icon-download-2.svg");
  background-position: 90% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  &:hover {
    background-color: #606060;
  }
}

.btn-download > a:hover {
  text-decoration: none;
}

.input {
  &-readonly {
    width: 90%;
    border: none;
    font-size: inherit;
    color: rgb(92, 168, 255);
    background: none;
    cursor: pointer;
  }
  &-feedback {
    color: #ff8389;
    margin-top: 0.25rem;
    font-size: 12px;
  }
  &.error {
    border-color: #fa4d56;
  }
}

.default-checkbox {
  height: 16px;
  width: 16px;
}
