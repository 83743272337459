@import "@scss/variables.scss";

.detail-table {
  &-row {
    display: flex;
    &-child {
      display: flex;
      border-right: 2px solid #171717;
      border-bottom: 1px solid #171717;
      align-items: flex-start;
      padding: 1rem;
      font-size: 14px;
      &-left {
        background: #3d3d3d;
        width: 25%;
      }

      &-right {
        background: #252525;
        width: 75%;
        overflow-x: auto;
        &-container {
          margin-bottom: 2rem;
        }
        &-label {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          position: absolute;
          right: 1rem;
        }
      }
    }
  }

  &-json {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
      padding-right: 0.5rem;
      padding-top: 0.5rem;
      width: 100%;
    }
    &-view {
      display: flex;
    }
  }
}
