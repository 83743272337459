@import "src/scss/variables.scss";

div.schedule-run {
  & .schedule-run-rate {
    display: flex;
    flex-direction: row;
    width: "70%";
    align-items: center;
    margin-bottom: 36px;
    margin-top: 28px;
    .number {
      width: 3rem;
      margin-top: 0;
    }
    & select {
      min-width: 110px;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-bottom: 1px solid #8c8c8c;
      height: 41px;
      color: #f3f3f3;
      padding-left: 10px;
      cursor: pointer;
      margin-left: 8px;
      -webkit-appearance: none;
      background: url("../../../assets/images/icons/select-carrot.svg")
        no-repeat right;
      background-color: #3d3d3d;
      background-position-x: 88px;
    }
    & select:hover {
      background-color: #4c4c4c;
    }
  }
  & div.space-line {
    border-bottom: 1px solid $selectOptionBorderColor;
    line-height: 0;
    margin: 20px 0 5px 0;
    & span {
      background: $selectBackgroundColor;
      padding: 1px 10px;
      padding-left: 0px;
      font-size: 13px;
      text-transform: uppercase;
      opacity: 0.7;
    }
  }
  & div.cron-expression {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    & > div {
      padding-right: 16px;
    }
    & input {
      margin-top: 8px;
      height: 40px;
      width: 40px;
    }
    & select {
      width: 128px;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-bottom: 1px solid #8c8c8c;
      height: 41px;
      color: #f3f3f3;
      padding-left: 10px;
      cursor: pointer;
      margin-top: 8px;
      -webkit-appearance: none;
      background: url("../../../assets/images/icons/select-carrot.svg")
        no-repeat right;
      background-color: #3d3d3d;
      background-position-x: 106px;
    }
  }
  & span {
    font-size: 10px;
  }
  & .expression-text {
    padding-top: 20px;
    text-align: center;
    color: $checkmarkBackgroundHover;
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: -40px;
    &.heading {
      margin-right: 8px;
    }
    &.exp {
      color: #98c379;
    }
  }
  & .expression-input {
    font-size: 15px;
    width: 360px;
    word-spacing: 30px;
    margin-bottom: 16px;
  }
}
