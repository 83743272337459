@import "src/scss/variables.scss";
$text-color: #f3f3f3;

.fi {
  &-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
  }

  &-subtext {
    font-size: 11px;
  }

  &-input {
    width: 100%;
    text-overflow: ellipsis;
    height: 2.5rem;
    padding: 0 1rem;
    border: none;
    border-bottom: 1px solid #8c8c8c;
    background-color: #3d3d3d;
    box-shadow: inset 0 0 0 2px transparent;
    transition: all 70ms ease;
    font-family: IBMPlexSans-Regular, sans-serif;
    color: $text-color;
    font-weight: 400;
    -webkit-transition: all 70ms ease;
    font-size: 14px;
    &:focus {
      box-shadow: inset 0px 0px 0px 2px #f3f3f3;
    }
  }

  &-label-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    label {
      font-weight: 500;
      width: -webkit-fill-available;
    }
  }

  &-icon {
    &-container {
      height: 2.5rem;
      min-width: 2.7rem;
      max-width: 2.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #424242;
      border: 0px;
      border-left: 2px solid #2b2b2b;
      border-bottom: 1px solid #8c8c8c;
      cursor: pointer;
      &:hover {
        background: #525252;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
    }

    &-copy {
      cursor: pointer;
      opacity: 1;
      height: 16px;
    }
    &-mask {
      cursor: pointer;
      fill: #8c8c8c;
      height: 18px;
    }
  }
}
