@import "src/scss/variables.scss";

.list {
  &-row {
    font-size: 0.95rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: baseline;
    & div:nth-child(2) {
      &:first-letter {
        text-transform: capitalize;
      }
    }
    & span {
      padding: 2px 3px;
    }
    & div:nth-child(2) {
      &.json-pretty:first-letter {
        text-transform: none;
      }
    }
  }
  &-image {
    margin-bottom: 1.25rem;
    padding-left: 1.5rem;

    img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
    }
  }
}

.step-number {
  font-size: $fontSizeMedium;
  text-align: center;
  border-radius: 100%;
  background-color: #505050;
  flex: 0 0 auto;
  display: flex;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 8px;
  flex-direction: column;
}

.instruction {
  &-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-size: 12px;
  }
  &-link {
    color: #65adff;
    margin: 0 3px;
    &:hover {
      text-decoration: underline;
    }
  }
  &-highlight-code {
    border: 1px solid #3a3939;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    padding: 0 8px;
    position: relative;
    background-color: #3a3939;
    font-size: 14px;
  }
  &-json-copy-icon {
    top: 0px;
    right: 0px;
  }
}

.highlight-wrapper {
  display: inline-flex;
  padding: 3px 0;
}
