.field-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  .help-icon {
    width: 16px;
    height: 16px;
    margin: 4px;
    background-image: url("../../../../../assets/images/icons/icon-help-new.svg");
    background-position: 50% 50%;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    position: relative;
    background-color: transparent;
    border: 0px;

    .help-tooltip {
      visibility: hidden;
      transition-property: visibility;
      transition-delay: 0.5s;
      width: 300px;
      height: auto;
      padding: 10px 12px;
      border-radius: 2px;
      background-color: #f3f3f3;
      color: #282828;
      font-weight: 400;
      font-family: IBMPlexSans-Regular, sans-serif;
      position: absolute;
      top: 28px;
      right: -3px;
      z-index: 100;
      font-size: 13px;
      display: flex;
      flex-direction: column;
      word-break: break-word;
      .header {
        font-size: 10px;
        font-weight: 600;
      }
    }
    .help-tooltip::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 100%;
      margin-left: -16px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent #f3f3f3 transparent;
    }
    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(90, 90, 90, 0.5);
    }
    &:hover {
      .help-tooltip {
        visibility: visible;
      }
    }
    &:hover::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      background-color: rgba(0, 0, 0, 1);
    }
  }
}
.example-title {
  margin-top: 10px;
  margin-bottom: 2px;
  font-family: IBMPlexSans-Medium, sans-serif;
}
