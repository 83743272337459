.gs {
  &-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  &-body {
    overflow-y: auto;
    height: calc(100vh - 55px);
  }
  &-title {
    padding: 4rem 4rem 3rem 4rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      .icon-wrapper {
        display: flex;
        width: 56px;
        height: 56px;
        margin-right: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #f3f3f3;
        .icon {
          width: 36px;
          height: 36px;
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 0;
        }
      }
      .title {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 1.875rem;
        font-weight: 400;
        letter-spacing: 0.32px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      .documentation {
        display: flex;
        color: #65adff;
        padding-right: 2rem;
        border-right: 1px solid #4c4c4c;
        a {
          color: #65adff;
          &:hover {
            color: #86bfff;
          }
          img {
            margin-left: 8px;
          }
        }
      }
      .status {
        display: flex;
        padding: 0 2rem;
        font-size: 14px;
        .dot {
          width: 10px;
          height: 10px;
          margin-right: 8px;
          border-radius: 100%;
          background-color: #6f6f6f;
          margin-top: 3px;
          &.green {
            background-color: #42be65;
          }
        }
      }
      button {
        height: 40px;
        min-height: 40px;
        padding: 10px 15px 10px 15px;
        background-color: #6f6f6f;
        color: #fff;
        border-style: none;
        border-width: 0px;
        opacity: 1;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  &-panel {
    padding: 0 4rem 4rem;
    display: flex;
    justify-content: space-between;
    &-inner {
      padding: 1.5rem 2rem 2rem;
      background-color: #282828;
      height: fit-content;
    }
    &-left {
      display: flex;
      width: 63%;
      margin-right: 4rem;
    }
    &-right {
      width: 37%;
      &-field-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
      }
      .unset_position {
        position: unset;
      }
    }
  }
  &-tab-wrap {
    border-bottom: 2px solid #252525;
  }
}
