.fs-error {
  &-container {
    display: flex;
    width: 100%;
    border-left: 3px solid #fb4b53;
    background-color: #3d3d3d;
    margin: 0;
    height: auto;
    padding: 0.8rem;
    color: #fff;
    justify-content: space-between;
  }
  &-text {
    margin-left: 0.8rem;
    color: #f3f3f3;
    font-size: 0.875rem;
    line-height: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.16px;
  }
}
