.td {
  &-container {
    display: flex;
    flex-direction: column;
    .status-block {
      padding: 12px 16px;
      display: flex;
      .status-color {
        color: #f3f3f3;
        line-height: 20px;
        font-weight: 300;
      }
    }
    .detail-block {
      display: flex;
      .collaboration {
        display: flex;
        flex-direction: column;
        width: 20%;
        margin-top: 20px;
        align-items: flex-end;
        .heading {
          display: flex;
          width: 167px;
          .title {
            margin-bottom: 24px;
            font-size: 15px;
            line-height: 24px;
            font-weight: 500;
            color: #f3f3f3;
          }
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          width: 167px;
          .box {
            cursor: pointer;
            height: 36px;
            display: flex;
            align-items: center;
            border: 1px solid #3d3d3d;
            background-color: #282828;
            color: #f3f3f3;
            font-size: 13px;
            text-decoration: none;
            padding: 10px 10px 10px 0;
            margin-bottom: 4px;
            .image {
              margin-right: 5px;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              width: 36px;
              height: 36px;
              background-size: 18px;
              padding-left: 10px;
              &.zoom {
                background-image: url(../../assets/images/snippets/icon-zoom.svg);
              }
              &.slack {
                background-image: url(../../assets/images/snippets/icon-slack.svg);
              }
              &.jira {
                background-image: url(../../assets/images/snippets/icon-jira.svg);
              }
            }
            div {
              width: -webkit-fill-available;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &.hov {
              &:hover {
                background-color: #393939;
                & > .image {
                  background-size: 12px;
                  background-image: url(../../assets/images/icons/icon-external.svg);
                }
              }
            }
            &:hover {
              background-color: #393939;
            }
            &.disable {
              opacity: 0.5;
              cursor: not-allowed;
              &:hover {
                background-color: #282828;
              }
            }
          }
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        padding-left: 16px;
        margin-top: 24px;
        width: 80%;
        &-heading {
          font-size: 14px;
          margin-bottom: 24px;
          font-size: 15px;
          line-height: 24px;
          font-weight: 500;
          color: #f3f3f3;
        }
        &-table {
          display: grid;
          gap: 12px;
          grid-template-columns: 0.4fr 1.5fr 0.4fr 1.5fr;
          .detail {
            display: flex;
            align-items: flex-start;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 20px;
            word-break: break-all;
            &-key {
              color: #bebebe;
              opacity: 0.8;
            }
            &-value {
              color: #f3f3f3;
              opacity: 0.9;
              line-height: 20px;
              padding-right: 60px;
              word-break: break-all;
            }
            img {
              height: 15px;
              width: 15px;
              margin-right: 7px;
              margin-top: 3px;
            }
          }
        }
      }
    }

    .executions {
      display: flex;
      margin-top: 24px;
    }
    .workflows {
      &-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        button {
          background: transparent;
          border: none;
          color: #5ca8ff;
          cursor: pointer;
          margin-top: 5px;
          margin-bottom: 5px;
          transition: all 70ms ease;
          padding-left: 0;
          &:hover {
            text-decoration: underline;
            text-decoration-color: #5ca8ff;
          }
        }
      }
      &-default {
        font-size: 8px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        background-color: #656565;
        color: #f3f3f3;
        padding: 0px 5px;
        border-radius: 2px;
        margin-left: 12px;
      }
      .rdt_TableRow {
        min-height: 50px;
      }
    }
    .rdt_TableCell {
      padding: 0.875rem 0.5rem 0.8125rem 0.9rem;
    }
    .rdt_TableCol_Sortable {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span {
        margin-right: 5px;
      }
      &:hover {
        background-color: #555;
      }
    }
    .tabs__nav-item-dark-inactive,
    .tabs__nav-item-dark-active {
      width: 10rem;
    }
  }
}

.actions-wrapper {
  display: flex;

  & > button {
    margin-right: 1rem;
  }
}

.workflows-wrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  min-height: 50px;
  & > button {
    background: transparent;
    border: none;
    color: #5ca8ff;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.block {
  padding-left: 17px !important;
  background-position: 0% 50%;
}

.act-btn {
  width: 85px;
  height: 30px;
  padding: 8px;
}

.act-icon {
  width: 40px;
  height: 30px;
}

.alert-body-json-copy-outerwrap {
  margin: 33px;
  top: 0px;
}
.alert-body-wrap {
  width: 36%;
  min-width: 600px;
  font-family: Inconsolata, monospace;
  font-size: 13px;
  margin-bottom: 3rem;
}
.alert-body-scroll-height {
  overflow-x: auto;
  max-height: 40vh;
  background-color: #252525;
}
.alert-body-icon-copy {
  background-color: #282828;
}
.alert-body-content {
  margin-bottom: 24px;
  padding-left: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #f3f3f3;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.16px;
}

.multiselect-filterable {
  input {
    background-color: #555;
    border: 0px solid transparent;
    &:hover {
      background-color: #616161;
    }
  }
  &.error {
    .bx--text-input {
      box-shadow: inset 0px 0px 0px 2px #f55;
    }
  }
}

.add-wf-content {
  overflow: unset;
  height: 200px;
  &-container {
    overflow: unset;
  }
}

.menu-open-top {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
