.incidents-barchart {
  @keyframes animateProgressBar {
    0% {
      width: 0px;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 24px;
    &:nth-last-child(1) {
      padding-bottom: 3rem;
    }
  }

  &-legend {
    display: flex;
    justify-content: space-between;
    color: #f3f3f3;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &-filler {
    display: block;
    height: 10px;
    border-radius: 3px;
    background-color: #303136;
  }

  &-progress {
    display: block;
    height: 10px;
    border-radius: 3px;
    background-color: #b177ff;
    animation: animateProgressBar 2s ease-in-out;
  }
}
