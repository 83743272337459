.snippet-details {
  &-header {
    margin-top: 10px;
    padding-left: 12px;
  }
  & span.tag {
    background-color: #3d3d3d;
    color: #bebebe;
    line-height: 1.5rem;
  }
}

.snippet-details-tabs-content {
  .rdt_TableBody {
    overflow-y: scroll !important;
  }
}
