.gs-copy-icon {
  align-self: flex-end;
  margin-bottom: -1rem;
  z-index: 1;
}

.gs-form-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 3.5rem;
  margin-top: 1rem;
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-left: 16px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    border: 2px solid;
    &:focus {
      border: 2px solid #f3f3f3;
    }

    &-submit {
      border-color: #6300ff;
      width: 100%;
      background-color: #6300ff;
    }
  }
}

.text-area {
  padding: 1rem;
  font-size: 14px;
}
