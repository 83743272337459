.no-result {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-title {
    font-size: 17px;
    color: #f3f3f3;
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  &-detail {
    color: #f3f3f3;
    opacity: 0.8;
    font-weight: 400;
    font-size: 14px;
    width: 310px;
    text-align: center;
    margin-bottom: 2rem;
  }
}
