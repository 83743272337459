.cloud-wc {
  margin: 2rem 0;
  display: flex;
  border-left: 3px solid #fed23a;
  background-color: #3d3d3d;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.16px;
  max-width: 34rem;
  align-items: flex-start;
  opacity: 0.9;
  &-icon {
    margin: 24px 1rem 0 1rem;
  }
  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &-header {
      font-size: 18px;
      font-family: IBMplexsans-Medium;
      line-height: 28px;
      margin: 0;
      padding: 4px 0;
      color: #f3f3f3;
      font-weight: 400;
      letter-spacing: 0.32px;
    }
    &-text {
      line-height: 17px;
      padding: 6px 0px;
      color: #f3f3f3;
      font-size: 13px;
    }
    &-btn {
      height: 2.5rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      background-color: #6300ff;
      padding: 8px 16px;
      color: #fff;
      font-weight: 400;
      max-width: 12rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      letter-spacing: 0.16px;
      text-decoration: none;
      &:hover {
        background-color: #6208f1;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
    }
  }
}
