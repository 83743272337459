@import "../variables.scss";

.tag {
  display: inline-block;
  height: 1.5rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.9375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.32px;

  &.tag_primary {
    height: 23px;
    margin-right: 8px;
    margin-left: 0rem;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: $primaryTagBackground;
    color: $primaryTagFontColor;
    font-size: $fontSizeMedium;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.16px;
  }

  &__innerWrap {
    // color: hsla(0, 0%, 95.3%, 0.7);
    font-size: $fontSizeMedium;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.16px;
    margin-top: 5px;
  }
}

.tag_primary-light {
  height: 23px;
  margin-right: 8px;
  margin-left: 0rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: $primaryTagLightBackground;
  color: $primaryTagLightFontColor;
  font-size: $fontSizeMedium;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.16px;
}
