.ch-container {
  position: relative;
  padding: 24px;
  border: 1px solid #2f3034;
  border-radius: 6px;
  background-color: #282828;
  color: #fff;
  margin-top: 1rem;
  height: 500px;
  overflow-y: auto;

  .text {
    font-size: 16px;
    font-weight: 500;
  }
  .subText {
    margin-top: 4px;
    color: hsla(0, 0%, 95.3%, 0.5);
    font-size: 12px;
  }
  .multilineLagend {
    .recharts-legend-wrapper {
      left: 42px !important;
    }
  }

  .recharts-legend-wrapper {
    left: 22px !important;
  }

  .chartWrapper {
    width: 100%;
    height: 71%;
  }
  .donutWrapper {
    width: 100%;
    height: 85%;
  }
}
