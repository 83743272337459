@import "base/normalize.css";
@import "./variables.scss";
@import "components/logo.scss";
@import "components/tag.scss";
@import "components/ui.scss";
@import "containers/headerNav.scss";
@import "containers/canvasMenu.scss";
@import "@carbon/themes/scss/themes";
$carbon--theme: $carbon--theme--g100;
@include carbon--theme();
@import "carbon-components/scss/globals/scss/styles.scss";
/*added here to prevent ReactSelect.scss precedence over common class scss*/
@import "@components/ui/React-Select/ReactSelect.scss";

@font-face {
  font-family: "Ibmplexmono";
  src: url(../assets/fonts/IBMPlexMono-Regular.otf) format("opentype");
}
@font-face {
  font-family: "IBMPlexSans-Regular";
  src: url(../assets/fonts/IBMPlexSans-Regular.otf) format("opentype");
}
@font-face {
  font-family: "IBMPlexSans-Italic";
  src: url(../assets/fonts/IBMPlexSans-Italic.otf) format("opentype");
}
@font-face {
  font-family: "IBMPlexSans-Medium";
  src: url(../assets/fonts/IBMPlexSans-Medium.otf) format("opentype");
}
@font-face {
  font-family: "IBMPlexSans-SemiBold";
  src: url(../assets/fonts/IBMPlexSans-SemiBold.otf) format("opentype");
}
/* tags */
body {
  overscroll-behavior: none;
  overflow-y: hidden;
  overflow-x: hidden;
  background: $tertiarySecondaryBackground;
  font-family: $fontFamily;
  color: $bodyFontColor;
  font-size: $bodyFontSize;
  -webkit-font-smoothing: subpixel-antialiased;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: $h4FontColor;
  font-size: $fontSizeLarge;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.32px;
}

h2 {
  color: #f3f3f3;
  font-size: 1.5rem;
  line-height: 2.675rem;
  letter-spacing: 0.32px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 6px;
  &:hover {
    background: #c0c0c0;
  }
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}

/* Corner color */
::-webkit-scrollbar-corner {
  background: #303030;
}

div {
  box-sizing: border-box;
}

.main {
  height: 100vh;
  display: flex;
  &-left {
    height: 100%;
    width: 4rem;
    flex-shrink: 0;
    background-color: $secondaryBackground;
    overflow: hidden;
  }
  &-right {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2rem 2.5rem;
    background: $tertiarySecondaryBackground;
  }
}

/* animations */

.fade-in {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  &-fast {
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.fade-fast {
  animation: fadeOutAnimation ease 10.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-slow {
  animation: fadeOutAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.scale-out {
  animation: scaleOut 0.2s ease-in-out 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-select-none {
  opacity: 0;
  animation: fadeInAnimation ease 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.slide-out-from-right {
  transform-origin: 100% 0%;
  animation: slideOutFromRight 0.3s ease-in-out 0s 1 reverse running;
}

.slide-out-from-left {
  transform-origin: 0% 100%;
  animation: slideOutFromLeft 0.5s ease-in-out 0s 1 reverse running;
}

.slide-in-to-right {
  transform-origin: 100% 0%;
  animation: slideInToRight 0.3s ease-in-out 1s 1 reverse running;
}

@keyframes slideOutFromRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slideOutFromLeft {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes slideInToRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
  THIS IS THE GENERATED FILE THAT IS PRODUCED USING THE SIMPLE LOOP SHOWN ABOVE
*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.styled-check-box {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  padding-right: 5px;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    opacity: 1;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 1px solid #6f6f6f;
  }

  &:hover + label:before {
    border: 1px solid #fff;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label {
    opacity: 1;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: #282828;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #282828, 4px 0 0 #282828, 4px -2px 0 #282828,
      4px -4px 0 #282828, 4px -6px 0 #282828, 4px -8px 0 #282828;
    transform: rotate(45deg);
  }
}

.styled-check-box:hover + label:before {
  background: transparent;
}

.styled-check-box:checked + label:after {
  left: 4px;
  top: 16px;
}

.styled-check-box:checked + label:before {
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
}

//task management
.table {
  font-size: 13px;
  &-description {
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-task-id {
    color: #5ca8ff;
    height: 26px;
    padding: 8px;
    background-color: #353535;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  &-task-name {
    color: #5ca8ff;
  }
  &-task-status {
    display: flex;
    width: 100%;
    align-items: center;
    .dot {
      height: 10px;
      width: 10px;
      background-color: #5f5f5f;
      border-radius: 50%;
      display: inline-block;
      &.pending {
        background-color: #4589ff;
      }
      &.active {
        background-color: #42be65;
      }
    }
    .status {
      margin-left: 8px;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}

.bold {
  font-weight: bolder;
  font-family: "IBMPlexSans-SemiBold";
}
