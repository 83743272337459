.lh {
  &-container {
    display: flex;
    position: fixed;
    top: 0;
    width: 100vw;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #171717;
    background-color: #282828;
    height: 3.5rem;
    padding-right: 16px;
  }
}
