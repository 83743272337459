.accordion {
  box-sizing: border-box;

  &-section {
    transition: max-height 0.2s linear;
  }

  &-label-container {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
  }

  &-label {
    color: hsla(0, 0%, 95.3%, 0.7);
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    &-arrow-left {
      padding-left: 10px;
    }
    &-arrow-right {
      margin-right: 0;
    }
  }

  &-arrow {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: calc(50% - 8px);
    background-image: url("./arrow__down.svg");
    background-position: 50% 50%;
    background-size: 10px;
    background-repeat: no-repeat;
    color: #fff;
    filter: invert(100%);

    &-down {
      transform: rotate(0deg);
      transition: transform 0.25s linear;
    }

    &-up {
      transform: rotate(180deg);
      transition: transform 0.25s linear;
    }

    &-right {
      right: 1rem;
    }

    &-left {
      left: 0;
    }
  }
}
