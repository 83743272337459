.emptystate {
  &-container {
    margin-top: 4rem;
    border: 1px solid #393939;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  &-divider {
    border: 1px solid #393939;
  }
  &-message-container {
    position: absolute;
    margin-left: 1.8rem;
  }
  &-message {
    font-weight: bold;
    line-height: 20px;
    font-size: 14px;
    color: #f3f3f3;
  }
  &-subtext {
    line-height: 20px;
    font-size: 14px;
    color: #f3f3f3;
    font-weight: 300;
  }
  &-warning-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: 2px;
  }
  &-circle-wrapper {
    margin-top: 2rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .emptystate-message-container {
      left: 2rem;
    }
  }
  &-circle {
    height: 320px;
    width: 320px;
    border: 40px solid #393939;
    border-radius: 100%;
  }
  &-barchart {
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 120%;
    }
    &-filler {
      display: block;
      height: 10px;
      border-radius: 3px;
      background-color: #303136;
    }
  }
}
