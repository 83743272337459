.label-inline {
  & > div {
    display: inline-block;
  }
}

.label-left {
  margin-right: 5px;
  display: inline-block;
}

.label-right {
  margin-right: 5px;
  display: inline-block;
}

.label-top {
  color: hsla(0, 0%, 95.3%, 0.7);
  font-size: 11;
  font-weight: 400;
}
