@import "../../../scss/variables.scss";

.integration-tile {
  position: relative;
  width: 194px;
  height: 164px;
  margin-right: 1.2rem;
  border: none;
  margin-bottom: 1.2rem;
  background-color: #282828;
  -webkit-transition: all 90ms ease;
  transition: all 90ms ease;
  color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    filter: brightness(130%);
  }
}

.settings-icon.radio__xl {
  height: 100%;
  width: 100%;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 1;
}

.settings-icon {
  position: relative;
  display: flex;
  width: 28px;
  height: 54%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.9;
}

.integration-title {
  font-size: $fontSizeHeading;
  line-height: 28px;
  width: 90%;
  display: flex;
  word-break: break-word;
}

.settings-name.radio__xl {
  height: 30%;
  padding-bottom: 0px;
  padding-left: 16px;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  display: flex;
}

.settings-name {
  position: relative;
  bottom: 0px;
  display: block;
  padding-left: 12px;
  width: 100%;
  text-align: left;
  padding-top: 0px;
  padding-right: 4px;
  align-items: center;
  font-size: $fontSizeMedium;
  line-height: 20px;
}

.setting-tile {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-position: 100% 25%;
  background-size: auto 80px;
  background-repeat: no-repeat;
  opacity: 0.12;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.status-icon {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  flex: 0 0 auto;
  border-radius: 100%;
  background-color: #3dbb61;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  &-inactive {
    background-color: #606060;
  }
}

.status {
  display: inline-block;
  margin-bottom: 0rem;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 0.32px;
  cursor: default;
}
