.env-health {
  &-selector {
    display: flex;
    align-items: center;
    .rs-control {
      background-color: #333;
      border-bottom: 1px solid #8c8c8c;
    }
    .rs-menu {
      margin-top: 5px;
      background-color: #333;
      border: none;
      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
      border: 2px solid #f3f3f3;
    }
  }

  &-region {
    min-width: 220px;
  }

  &-multiselect {
    width: 220px;
    border-radius: 5px;
    border: none;

    & .bx--list-box__menu {
      margin-top: 5px;
      border: 2px solid #f3f3f3;
    }
    & .bx--list-box__field {
      border-bottom: 1px solid #8c8c8c;
    }
    & .bx--text-input {
      min-width: 220px;
      border: none;
      background-color: #333;
    }
  }

  &-arrow-right {
    margin-bottom: -14px;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 14px;
    width: 14px;
    filter: brightness(0.5);
  }

  &-btn-refresh {
    margin-left: 2rem;
    margin-top: 28px;
    padding-left: 16px;
    padding-right: 16px;
    color: #fff;
    border: none;
    height: 40px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #6300ff;
    cursor: pointer;
    &:active {
      box-shadow: inset 0px 0px 0px 2px #f3f3f3;
    }
  }
  &-btn-refresh[disabled] {
    background-color: #6300ff;
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: #6300ff;
    }
    &:focus {
      box-shadow: none;
      background-color: #6300ff;
    }
    &:active {
      box-shadow: none;
    }
  }
  &-refresh-wrap {
    padding-right: 16px;
  }

  &-data-table {
    margin-top: 3rem;
    .rdt_TableRow {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.16px;
    }
    .rdt_TableBody {
      overflow-y: unset;
      max-height: fit-content;
    }
    .text_overflow {
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tooltip {
      width: 15rem;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0.5rem;
      color: #171717;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

// Data Table

.service-header {
  display: flex;
  justify-items: center;
  align-items: center;
  min-height: 5rem;

  &-icon {
    width: 34px;
    height: 34px;
    margin-right: 12px;
    border-radius: 5px;
    background-image: none;
    background-color: #f3f3f3;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      height: 20px;
      width: 20px;
    }
  }

  &-text {
    color: #f3f3f3;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
  }
}

.status-type-block {
  height: 28px;
  min-width: 40px;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.2px;
  &.ok {
    background-color: rgba(111, 220, 140, 0.2);
    color: #6fdc8c;
  }
  &.disrupted {
    background-color: rgba(253, 139, 139, 0.2);
    color: #ff5e5e;
  }
  &.degraded {
    background-color: rgba(255, 253, 119, 0.2);
    color: #fcff46;
  }
  &.unknown {
    background-color: rgba(157, 107, 223, 0.2);
    color: #9d6bdf;
  }
}
